@tailwind base;
@tailwind components;
@tailwind utilities;
body{
    overflow-x: hidden;
}

  
  .mySwiper1 .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  
  .mySwiper1  .swiper-slide img {
    display: block;
    width: 100%;
    height: 30rem;
    object-fit: cover;
  }
   
  .bg1{
    background-image: url('./Assets/bg_245480c5-1f59-42ff-8491-923d2f6e428b.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  .bg2{
    background-image: url('./Assets/bg-2_2dcc57f2-f6c6-4624-b079-3bb4318d39d3.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  .bg3{
    background-image: url('./Assets/bg-3.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;

  }
  .bg7{
    background-image: url('./Assets/02.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;

  }
  .bg6{
    background-image: url('./Assets/abo-02.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .bg-video {
    position: fixed;
    left: 0;
    top: 0;
    background-size: cover;
    background: no-repeat center;
    margin-left: 50vw;
    user-select: none;
    pointer-events: none;
  
    /*custom styles, for example grayscale appearance*/
    filter: grayscale(0.8);
  
    /*additional tweaks for positioning, read more in the link above*/
    height: 100%;
    width: 100%; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
  }
 .hero-slider {
    content: "";
    position: absolute;
    right: 80px;
    top: -20px;
    width: 400px;
    height: 400px;
    background: #03a297;
    border-radius: 0 50% 50% 50%;
    transform: rotate(20deg);
}
.sub-title {
  color: white;
  font-size: 16px;
  background: #03a297;
  padding: 6px 20px 6px 25px;
  clip-path: polygon(100% 0, 95% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);
}
.bg4{
  background-image: url('./Assets/03.png');
  background-size: cover;
  background-position: center;

  background-repeat: no-repeat;
}
.bg5{
  background-image: url('./Assets/bg.png');
  background-size: cover;
  background-position: center;
 
  background-repeat: no-repeat;
}
.theme-btn1{
  content: "";
  height: 300px;
  width: 300px;
  background: #fd6a6a;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
z-index: 9;
}
.theme-btn {
  position: relative;
  font-size: 16px;
  background:#03a297;
  color: white;
  padding: 10px 20px;

  vertical-align: middle;
  text-transform: capitalize;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;

  transition:all .5s ease-in-out;;
  z-index: 1;
}
.video-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  height: 450px;

}
 .play-btn {
  display: inline-block;
  padding: 0;
  height: 75px;
  width: 75px;
  color: white;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.play-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 75px;
  width: 75px;
  line-height: 75px;

  font-size: 20px;
  text-align: center;
  background:#fd6a6a;
  color: white !important;
  position: absolute;
  border-radius: 50%;
  z-index: 1;
}